import React from 'react'
import p5Types from 'p5'
import { generatePastelColor, generateRandomInt } from '../common'

import { P5CanvasInstance, ReactP5Wrapper, SketchProps } from '@p5-wrapper/react'
import { ourSolarSystem } from '../common/solar'

/**
 * Defines the properties for a Sketch space, which extends the SketchProps interface.
 * @interface SpaceProps
 * @extends SketchProps
 * @property {boolean} randomize - A boolean indicating whether or not to randomize the space.
 * @property {(p5: p5Types) => void} [setP5] - A function that sets the p5 instance for the space.
 */
type SpaceProps = SketchProps & {
	randomize: boolean
	setP5?: (p5: p5Types) => void
}

/**
 * A type representing a force with x and y components.
 * @typedef {Object} Force
 * @property {number} x - The x component of the force.
 * @property {number} y - The y component of the force.
 */
type Force = {
	x: number
	y: number
}

/**
 * Represents a celestial body in the intra-solar system.
 * @typedef {Object} IntraSolarBody
 * @property {number} mass - The mass of the celestial body.
 * @property {p5Types.Vector} pos - The position vector of the celestial body.
 * @property {p5Types.Vector} vel - The velocity vector of the celestial body.
 * @property {number} diameter - The diameter of the celestial body.
 * @property {p5Types.Vector[]} trail - An array of position vectors representing the trail of the celestial body.
 * @property {number} trailLen - The length of the trail array.
 * @property {string | number} color - The color of the celestial body.
 *
 */
type IntraSolarBody = {
	mass: number
	pos: p5Types.Vector
	vel: p5Types.Vector
	diameter: number
	trail: p5Types.Vector[]
	trailLen: number
	color: string | number

	show: () => void
	move: () => void
	applyForce: (force: Force) => void
	attract: (child: IntraSolarBody) => void
}

/**
 * Represents an extra solar body object with properties such as color, position, age, and size.
 * @typedef {Object} ExtraSolarBody
 * @property {string} color - The color of the extra solar body.
 * @property {p5Types.Vector} position - The position of the extra solar body.
 * @property {number} age - The age of the extra solar body.
 * @property {number} deadFrom - The age at which the extra solar body dies.
 * @property {number} maxSize - The maximum size of the extra solar body.
 * @property {number} maxAgeMultiplier - The maximum age multiplier of the extra solar body.
 * @property {Function} grow - A function that increases
 */
type ExtraSolarBody = {
	color: string
	position: p5Types.Vector
	age: number
	deadFrom: number
	maxSize: number
	maxAgeMultiplier: number

	grow: () => void
	shine: () => void
	show: () => void
}

/**
 * A function that creates a sketch using the p5.js library. The sketch displays a solar system with planets and stars.
 * @param {P5CanvasInstance<SpaceProps>} p5 - The p5.js instance to use for the sketch.
 * @returns None
 */
function sketch(p5: P5CanvasInstance<SpaceProps>) {
	let randomize = false
	let setP5: (p5: p5Types) => void = () => {}

	let planets: IntraSolarBody[] = []
	let sun: IntraSolarBody
	let stars: ExtraSolarBody[] = []

	// True G is too slow for our sim: 6.6743 * 10 ** -11
	const G = 6.6743 * 10 ** -7
	const trailLength = 200
	const numPlanets = randomize ? generateRandomInt(3, 8) : 8

	p5.windowResized = () => {
		p5.resizeCanvas(window.innerWidth, window.innerHeight)
	}

	p5.updateWithProps = (props) => {
		if (props.randomize !== randomize) {
			randomize = props.randomize
			p5.noLoop()
			p5.setup()
			p5.loop()
		}
		// Propagate the setP5 function upwards
		setP5 = props.setP5 ?? (() => {})
	}

	p5.setup = () => {
		p5.createCanvas(window.innerWidth, window.innerHeight)
		planets = []
		stars = []

		setP5?.(p5)

		// Stars
		let starCount = randomize ? generateRandomInt(100, 500) : 200
		while (starCount--) {
			generateStar(starCount)
		}

		// Sun
		sun = new (Planet as any)(
			randomize ? p5.random(1500, 300_000_000) : 3_329_789,
			randomize ? p5.createVector(p5.random(-100, 100), p5.random(-100, 100)) : p5.createVector(0, 0),
			p5.createVector(0, 0),
			randomize ? generatePastelColor() : '#f9d71c',
			randomize ? p5.random(10, 200) : 109.3 / (p5.width / 2) + 109.3 / 2
		)

		function Planet(this: any, _mass: number, _pos: p5Types.Vector, _vel: p5Types.Vector, color: string | number, _diameter: number) {
			this.mass = _mass // kg
			this.pos = _pos
			this.vel = _vel
			this.diameter = _diameter // meters
			this.radius = this.diameter / 2
			this.trail = []
			this.trailLen = Infinity
			this.color = color

			this.show = function () {
				p5.stroke(150, 50)
				for (let i = 0; i < this.trail.length - 1; i++) {
					setShadowAndBlur()
					p5.line(this.trail[i].x, this.trail[i].y, this.trail[i + 1].x, this.trail[i + 1].y)
				}
				p5.fill(this.color ?? 255)
				p5.noStroke()

				if (this.vel.x === 0 && this.vel.y === 0) {
					setShadowAndBlur(this.color, this.radius)
				}

				p5.ellipse(this.pos.x, this.pos.y, this.diameter, this.diameter)
			}

			this.move = function () {
				this.pos.x += this.vel.x
				this.pos.y += this.vel.y
				this.trail.push(p5.createVector(this.pos.x, this.pos.y))
				if (this.trail.length > trailLength) this.trail.splice(0, 1)
			}

			// Newton’s second law: F = ma
			this.applyForce = function (force: Force) {
				this.vel.x += force.x / this.mass
				this.vel.y += force.y / this.mass
			}

			// Newton's law of universal gravitation
			// F = G * (m_1 * m_2)/(r^2)
			this.attract = function (child: IntraSolarBody) {
				const r = p5.dist(this.pos.x, this.pos.y, child.pos.x, child.pos.y)
				const f = this.pos.copy().sub(child.pos)
				f.setMag((G * this.mass * child.mass) / (r * r))
				child.applyForce(f)
			}
		}

		function Star(this: any, position: p5Types.Vector) {
			this.color = '#ffffff'
			this.position = position
			this.age = 0
			this.deadFrom = 0
			this.maxSize = p5.random(10, 30)
			this.maxAgeMultiplier = p5.random(2, 5)

			this.show = function () {
				const diameter = this.diameter()
				if (diameter === 0) {
					return
				}

				setShadowAndBlur(this.color, this.shadowAmount)
				p5.fill(this.color ?? 255)
				p5.noStroke()
				p5.ellipse(this.position.x, this.position.y, diameter, diameter)
			}

			this.diameter = function () {
				const s = this.age - this.deadFrom
				return Math.max(Math.min(s, this.maxSize), 0)
			}

			this.shine = function () {
				this.shadowAmount = p5.map(p5.noise(this.position.x, this.age, p5.frameCount), 0, 1, this.age, this.age * 2)
			}

			this.grow = function () {
				this.age += 0.01

				if (this.diameter() > 0) {
					if (this.age > this.maxSize * this.maxAgeMultiplier) {
						this.deadFrom += 0.2 * this.maxAgeMultiplier
					}
				} else {
					if (!this.dead && this.starDeath) {
						this.starDeath()
					}
					this.dead = true
				}
			}
		}

		// Initialize the planets
		function generatePlanet(
			mass: number = p5.random(0, 400),
			color: string | number = generatePastelColor(),
			diameter: number = p5.random(0.1, 20),
			distance: number = p5.random(0.1, 35),
			destabilize: number = p5.random(0, 0.5),
			randomOrbitalPosition: boolean = true,
			randomize: boolean = true
		) {
			const MAX_RANDOM_DIST = 35

			const radius =
				(distance / (randomize ? MAX_RANDOM_DIST : Math.max(...ourSolarSystem.map((planet) => planet.distance)))) * (p5.width / 2) +
				sun.diameter / 2

			const angle = randomOrbitalPosition ? p5.random(0, p5.TWO_PI) : 0

			// Translate into cartesian coordinates
			const planetPos = p5.createVector(radius * p5.cos(angle), radius * p5.sin(angle))

			// Mean orbital speed equation, orbital velocity
			// v = sqrt((G * M) / r)
			const planetVel = planetPos.copy()
			planetVel.rotate(p5.HALF_PI)
			planetVel.normalize()
			planetVel.mult(p5.sqrt((G * sun.mass) / radius))

			if (destabilize) planetVel.mult(1 + destabilize)

			return new (Planet as any)(mass, planetPos, planetVel, color, diameter)
		}

		if (randomize) {
			for (let i = 0; i < numPlanets; i++) {
				planets.push(generatePlanet())
			}
		} else {
			for (const element of ourSolarSystem) {
				planets.push(generatePlanet(element.mass, element.color, element.diameter, element.distance, element.eccentricity, false, false))
			}
		}

		function generateStar(index: number) {
			const s = new (Star as any)(p5.createVector(p5.random(-p5.width, p5.width), p5.random(-p5.height, p5.height)))
			s.maxSize = (index % 2) + 1
			stars[index] = s
			s.starDeath = () => generateStar(index)
		}

		function setShadowAndBlur(color: string | null = null, blur: number | null = null) {
			//@ts-ignore
			p5.drawingContext.shadowColor = color
			//@ts-ignore
			p5.drawingContext.shadowBlur = blur
		}
	}

	p5.draw = () => {
		p5.background(20, 20, 20)
		p5.stroke(255)

		// Revolve in center of screen
		p5.translate(p5.width / 2, p5.height / 2)

		// Zoom
		p5.scale(1.5)

		// Stars
		stars.forEach((star) => {
			star.grow()
			star.shine()
			star.show()
		})

		// Planets with velocity of zero
		const distant = planets.filter((planet) => planet.vel.y < 0)
		distant.forEach((planet) => {
			sun.attract(planet)
			planet.move()
			planet.show()
		})

		// Sun
		sun.show()

		// Planets in front of Sun
		const close = planets.filter((planet) => planet.vel.y > 0)
		close.forEach((planet) => {
			sun.attract(planet)
			planet.move()
			planet.show()
		})
	}
}

export default function Space(props: any) {
	return (
		<ReactP5Wrapper
			sketch={sketch}
			randomize={props.randomize}
			setP5={props.setP5}
		/>
	)
}
