// https://en.wikipedia.org/wiki/List_of_gravitationally_rounded_objects_of_the_Solar_System
// https://astronomy.stackexchange.com/questions/14032/color-of-planets

export const ourSolarSystem = [
	// Mercury
	{
		mass: 0.055, // Mass relative to earth
		color: '#1a1a1a', // Average Color
		diameter: 0.3826, // Diameter Relative to Earth
		distance: 0.38709893, // Mean distance from sun
		eccentricity: 0.20563069 // Eccentricity of orbit
	},
	// Venus
	{
		mass: 0.815,
		color: '#e6e6e6',
		diameter: 0.9488,
		distance: 0.72333199,
		eccentricity: 0.00677323
	},
	// Earth
	{
		mass: 1,
		color: 'blue',
		diameter: 1,
		distance: 1,
		eccentricity: 0.01671022
	},
	// Mars
	{
		mass: 0.107,
		color: '#993d00',
		diameter: 0.53247,
		distance: 1.52366231,
		eccentricity: 0.09341233
	},
	// Jupiter
	{
		mass: 318,
		color: '#b07f35',
		diameter: 11.209,
		distance: 5.20336301,
		eccentricity: 0.04839266
	},
	// Saturn
	{
		mass: 95,
		color: '#b08f36',
		diameter: 9.449,
		distance: 9.53707032,
		eccentricity: 0.0541506
	},
	// Uranus
	{
		mass: 14.5,
		color: '#5580aa',
		diameter: 4.007,
		distance: 19.19126393,
		eccentricity: 0.04716771
	},
	// Neptune
	{
		mass: 17,
		color: '#366896',
		diameter: 3.883,
		distance: 30.06896348,
		eccentricity: 0.00858587
	}
]
