import React, { useEffect, useRef, useState } from 'react'
import './App.scss'
import Page from './components/Page'
import p5Types from 'p5'

import { useScroll } from 'react-use'
import HomePage from './pages/HomePage'
import { isMobile } from 'react-device-detect'
import clsx from 'clsx'

const App = () => {
	const [spaceSketch, setSpaceSketch] = useState<p5Types>()
	const [paused, setPaused] = useState<boolean>(false)
	const [randomize, setRandomize] = useState<boolean>(false)

	const scrollRef = useRef(null)
	const { y } = useScroll(scrollRef)

	useEffect(() => {
		if (y >= window.innerHeight && !paused) {
			spaceSketch?.frameRate(0)
			setPaused(true)
			console.info('Pausing the sketch.')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [y, window.innerHeight])

	return (
		<div
			className={clsx({
				mobile: isMobile
			})}
		>
			<div
				className='content bg-eerie-black-100 overflow-x-hidden overflow-y-auto relative scroll-smooth'
				ref={scrollRef}
				style={{
					height: window.innerHeight
				}}
			>
				<Page id='home'>
					<HomePage
						spaceSketch={spaceSketch}
						setSpaceSketch={setSpaceSketch}
						paused={paused}
						setPaused={setPaused}
						randomize={randomize}
						setRandomize={setRandomize}
					/>
				</Page>
			</div>
		</div>
	)
}

export default App
