import React, { useEffect, useState } from 'react'
import Space from '../components/Space'
import p5Types from 'p5'
import ControlPanel from '../components/ControlPanel'

/**
 * A functional component that renders a SpaceRoute.
 * @returns None
 */
const SpaceRoute = () => {
	useEffect(() => {
		let stylesArray = [
			'color: #fff',
			'padding: 10px 20px',
			'line-height: 35px',
			'width : 70px',
			'height : 70px',
			'border : 5px solid black'
		].join(';')

		console.log('%c Space: The final frontier. 🚀', stylesArray)
	}, [])

	const [spaceSketch, setSpaceSketch] = useState<p5Types>()
	const [paused, setPaused] = useState<boolean>(false)
	const [randomize, setRandomize] = useState<boolean>(false)

	return (
		<div id='space'>
			<Space
				onHomePage={false}
				setP5={setSpaceSketch}
				randomize={randomize}
			/>

			<ControlPanel
				onHomePage={false}
				spaceSketch={spaceSketch}
				setSpaceSketch={setSpaceSketch}
				paused={paused}
				setPaused={setPaused}
				randomize={randomize}
				setRandomize={setRandomize}
			/>
		</div>
	)
}

export default SpaceRoute
