/**
 * Generates a random pastel color.
 * @returns A string representing a random pastel color in hexadecimal format.
 */
export const generatePastelColor = () => {
	const R = Math.floor(Math.random() * 127 + 127)
	const G = Math.floor(Math.random() * 127 + 127)
	const B = Math.floor(Math.random() * 127 + 127)

	const rgb = (R << 16) + (G << 8) + B
	return `#${rgb.toString(16)}`
}

/**
 * Returns a debounced version of the given function that will only execute after a certain amount of time has passed.
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The amount of time to wait before executing the debounced function.
 * @returns A debounced version of the given function.
 */
export function debounce(func: (...args: any[]) => any, wait: number) {
	let timeout: any

	return function (this: any, ...args: any[]) {
		clearTimeout(timeout)
		timeout = setTimeout(() => func.apply(this, args), wait)
	}
}

/**
 * Generates a random integer between the given minimum and maximum values (inclusive).
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @returns {number} A random integer between the minimum and maximum values.
 */
export function generateRandomInt(min: number, max: number): number {
	// The minimum value is inclusive, and the maximum value is exclusive,
	// so we need to add 1 to the difference between the max and min.
	const range = max - min + 1
	return Math.floor(Math.random() * range) + min
}

/**
 * Determines if two objects are equal by comparing their properties and values.
 * @param {any} obj1 - The first object to compare.
 * @param {any} obj2 - The second object to compare.
 * @returns {boolean} - True if the objects are equal, false otherwise.
 */
export function isEqual(obj1: any, obj2: any): boolean {
	// Get the object type
	const type = Object.prototype.toString.call(obj1)

	// If the two objects are not the same type, return false
	if (type !== Object.prototype.toString.call(obj2)) return false

	// If items are not an object or array, return false
	if (!['[object Array]', '[object Object]'].includes(type)) return false

	// Compare the length of the length of the two items
	const obj1Length = type === '[object Array]' ? obj1.length : Object.keys(obj1).length
	const obj2Length = type === '[object Array]' ? obj2.length : Object.keys(obj2).length
	if (obj1Length !== obj2Length) return false

	// Compare two items
	const compare = (item1: any, item2: any) => {
		// Get the object type
		const itemType = Object.prototype.toString.call(item1)

		// If an object or array, compare recursively
		if (['[object Array]', '[object Object]'].includes(itemType)) {
			if (!isEqual(item1, item2)) return false
		}

		// Otherwise, do a simple comparison
		else {
			// If the two items are not the same type, return false
			if (itemType !== Object.prototype.toString.call(item2)) return false

			// Else if it's a function, convert to a string and compare
			// Otherwise, just compare
			if (itemType === '[object Function]') {
				if (item1.toString() !== item2.toString()) return false
			} else if (item1 !== item2) return false
		}
	}

	// Compare properties
	if (type === '[object Array]') {
		for (let i = 0; i < obj1Length; i++) {
			if (compare(obj1[i], obj2[i]) === false) return false
		}
	} else {
		for (const key in obj1) {
			if (obj1.hasOwnProperty(key) && compare(obj1[key], obj2[key]) === false) return false
		}
	}

	// If nothing failed, return true
	return true
}
