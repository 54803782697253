import React from 'react'
import Typewriter from 'typewriter-effect'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'

const Greeting = () => {
	const [links, setShowLinks] = React.useState(false)

	const variants = {
		open: { opacity: 1 },
		closed: { opacity: 0 }
	}

	return (
		<div
			id='greeting'
			className='absolute text-[5vmin] top-[10%] left-[10%]'
		>
			<Typewriter
				onInit={(typewriter) => {
					typewriter
						.typeString("<span>Hi, I'm Luke.</span>")
						.pauseFor(1500)
						.typeString('<br>')
						.callFunction(() => {
							const cursor = document.querySelector('.Typewriter__cursor')
							if (cursor) {
								cursor.classList.add('cursor-small')
							}
						})
						.typeString('<span id="greeting-links">Take a look at my links below!</span>')
						.start()
						.callFunction(() => setShowLinks(true))
				}}
			/>

			<motion.div
				id='links'
				initial={{ opacity: 0 }}
				animate={links ? 'open' : 'closed'}
				variants={variants}
				transition={{ duration: 2 }}
				className='flex flex-row align-center ml-[-1rem]'
			>
				<button
					title='GitHub'
					className='px-4'
					onClick={(e) => {
						e.stopPropagation()
						window.location.href = 'https://github.com/lprescott'
						return null
					}}
				>
					<FontAwesomeIcon icon={faGithub} />
				</button>
				<button
					title='LinkedIn'
					className='px-4'
					onClick={(e) => {
						e.stopPropagation()
						window.location.href = 'https://www.linkedin.com/in/lukerobertprescott/'
						return null
					}}
				>
					<FontAwesomeIcon icon={faLinkedin} />
				</button>
				<button
					title='Contact Me'
					className='px-4'
					onClick={(e) => {
						e.stopPropagation()
						window.location.href = 'mailto:luke.prescott@presport.us'
					}}
				>
					<FontAwesomeIcon icon={faEnvelope} />
				</button>
			</motion.div>
		</div>
	)
}

export default Greeting
