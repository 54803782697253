import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import NotFound from './components/NotFound'
import SpaceRoute from './pages/SpaceRoute'

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		errorElement: <NotFound />
	},
	{
		path: '/space',
		element: <SpaceRoute />,
		errorElement: <NotFound />
	}
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<RouterProvider router={router} />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
