import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faArrowsRotate, faPlay, faShuffle } from '@fortawesome/free-solid-svg-icons'
import { SpaceSettings } from '../pages/HomePage'

/**
 * A component that displays the control panel for the space settings.
 * @param {SpaceSettings} props - The space settings object.
 * @returns A React component that displays the control panel.
 */
const ControlPanel = (props: SpaceSettings) => {
	return (
		<div
			id='control-panel'
			className='absolute right-0 bottom-0 p-[1em] rounded flex flex-col items-center text-[4vmin]'
		>
			<button
				title='Refresh'
				onClick={() => {
					props.spaceSketch?.setup()
					props.spaceSketch?.redraw()
				}}
			>
				<FontAwesomeIcon icon={faArrowsRotate} />
			</button>
			<button
				title='Play/Pause'
				onClick={() => {
					props.spaceSketch?.frameRate(props.paused ? 60 : 0)
					props.setPaused(!props.paused)
				}}
			>
				<FontAwesomeIcon
					icon={props.paused ? faPlay : faPause}
					style={{
						color: props.paused ? 'white' : undefined
					}}
				/>
			</button>
			<button
				title='Toggle Randomization'
				onClick={() => props.setRandomize(!props.randomize)}
			>
				<FontAwesomeIcon
					icon={faShuffle}
					style={{
						color: props.randomize ? 'white' : undefined
					}}
				/>
			</button>
		</div>
	)
}

export default ControlPanel
