import React from 'react'
import '../notfound.css'

/**
 * Renders a "Page Not Found" component that displays a message indicating that the requested page does not exist.
 * @returns {JSX.Element} - A React component that displays a "Page Not Found" message.
 */
const NotFound = () => {
	document.title = 'Page Not Found'

	return (
		<section
			className='page-not-found flex justify-center items-center snap-start'
			style={{
				height: window.innerHeight
			}}
		>
			<h1>Page Not Found</h1>
			<p>Sorry, but the page you were trying to view does not exist.</p>
		</section>
	)
}

export default NotFound
