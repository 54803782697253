import Space from '../components/Space'
import p5Types from 'p5'
import ControlPanel from '../components/ControlPanel'
import Greeting from '../components/Greeting'

export type SpaceSettings = {
	spaceSketch: p5Types | undefined
	setSpaceSketch: (spaceSketch: p5Types) => void
	paused: boolean
	setPaused: (paused: boolean) => void
	randomize: boolean
	setRandomize: (randomize: boolean) => void
	onHomePage?: boolean
}

/**
 * The HomePage component renders the main page of the application, which includes a Space component,
 * a Greeting component, and a ControlPanel component.
 * @param {SpaceSettings} props - An object containing the settings for the Space component.
 * @returns The rendered HomePage component.
 */
const HomePage = (props: SpaceSettings) => {
	return (
		<>
			<Space
				onHomePage
				setP5={props.setSpaceSketch}
				randomize={props.randomize}
			/>

			<Greeting />

			<ControlPanel
				spaceSketch={props.spaceSketch}
				setSpaceSketch={props.setSpaceSketch}
				paused={props.paused}
				setPaused={props.setPaused}
				randomize={props.randomize}
				setRandomize={props.setRandomize}
			/>
		</>
	)
}

export default HomePage
