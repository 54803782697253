import React from 'react'
import clsx from 'clsx'
type Props = {
	id: string
	children?: React.ReactNode
	shadow?: Boolean
	style?: Object
}

/**
 * A functional component that renders a page section with the given props.
 * @param {Props} props - The props object containing the id, shadow, style, and children of the page.
 * @returns A section element with the given props.
 */
const Page = (props: Props) => {
	return (
		<section
			id={props.id}
			className={clsx({
				'page flex justify-center items-center snap-start': true,
				'shadow-bottom': props.shadow
			})}
			style={props.style}
		>
			{props.children}
		</section>
	)
}

export default Page
